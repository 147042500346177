<template>
  <v-layout
    v-if="getPermission('property:create') || getPermission('property:update')"
  >
    <v-col
      class="custom-content-container pt-0"
      cols="12"
      :md="isDialog ? 12 : 9"
      :sm="isDialog ? 12 : 9"
    >
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 60vh; position: relative"
      > -->
      <v-layout class="d-block">
        <v-row>
          <v-col class="iv-custom-field pt-0" cols="12" md="12" sm="12">
            <v-container fluid>
              <v-row>
                <v-col class="pt-0" md="12" sm="12">
                  <v-row>
                    <v-col md="6" sm="6">
                      <v-switch
                        v-model="trackGPS"
                        class="mt-0"
                        inset
                        label="Get your current location"
                        v-on:change="getUserCurrentAddress"
                        color="cyan"
                      ></v-switch>
                    </v-col>
                    <v-col md="6" sm="6">
                      <v-hover v-slot="{ hover }">
                        <div class="custom-google-autocomplete">
                          <v-text-field
                            id="google-address-map"
                            v-model="searchAddress"
                            append-icon="search"
                            dense
                            filled
                            label="Search Address"
                            solo
                            flat
                            color="cyan"
                            :loading="autocompleteLoading"
                            v-on:keyup="getGoogleAddress"
                            v-on:blur="isFocused = false"
                            v-on:focus="isFocused = true"
                          >
                          </v-text-field>
                          <v-list
                            v-if="
                              (hover || isFocused) && autocompleteList.length
                            "
                            two-line
                            elevation="4"
                            class="custom-google-autocomplete-list"
                          >
                            <v-list-item
                              v-for="(address, index) in autocompleteList"
                              :key="index"
                              link
                              v-on:click="selectGoogleAddress(index)"
                            >
                              <v-list-item-icon class="m-0 mr-3 my-auto">
                                <v-icon color="cyan"> mdi-map-marker </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content
                                class="py-1"
                                v-if="
                                  lodash.isEmpty(
                                    address.structured_formatting
                                  ) === false
                                "
                              >
                                <v-list-item-title class="font-size-14">{{
                                  address.structured_formatting.main_text
                                }}</v-list-item-title>
                                <v-list-item-subtitle class="font-size-12">{{
                                  address.structured_formatting.secondary_text
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-content class="py-1" v-else>
                                <v-list-item-title class="font-size-14">{{
                                  address.description
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="4" sm="12">
                  <label>Unit No.</label>
                  <v-text-field
                    v-model="propertyAddress.unit_no"
                    :rules="[
                      validateRules.maxLength(
                        propertyAddress.unit_no,
                        'Unit No.',
                        100
                      ),
                    ]"
                    dense
                    filled
                    label="Unit No."
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="12">
                  <label>Address Line 1</label>
                  <v-text-field
                    v-model="propertyAddress.street_1"
                    :rules="[
                      validateRules.required(
                        propertyAddress.street_1,
                        'Address Line 1'
                      ),
                      validateRules.maxLength(
                        propertyAddress.street_1,
                        'Address Line 1',
                        255
                      ),
                    ]"
                    dense
                    filled
                    label="Address Line 1"
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="12">
                  <label>Address Line 2</label>
                  <v-text-field
                    v-model="propertyAddress.street_2"
                    :rules="[
                      validateRules.maxLength(
                        propertyAddress.street_2,
                        'Address Line 2',
                        255
                      ),
                    ]"
                    dense
                    filled
                    label="Address Line 2"
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="12">
                  <label>Postal Code</label>
                  <v-text-field
                    v-model="propertyAddress.zip_code"
                    :rules="[
                      validateRules.required(
                        propertyAddress.zip_code,
                        'Postal Code'
                      ),
                      validateRules.maxLength(
                        propertyAddress.zip_code,
                        'Postal Code',
                        10
                      ),
                    ]"
                    dense
                    filled
                    v-mask="'############'"
                    label="Postal Code"
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="4" sm="12">
                  <label>Country</label>
                  <v-text-field
                    v-model="propertyAddress.country"
                    :rules="[
                      validateRules.required(
                        propertyAddress.country,
                        'Country'
                      ),
                      validateRules.maxLength(
                        propertyAddress.country,
                        'Country',
                        10
                      ),
                    ]"
                    dense
                    filled
                    label="Country"
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col class="d-none" md="4" sm="12">
                  <label>Latitude</label>
                  <v-text-field
                    v-model="propertyAddress.latitude"
                    :rules="[
                      validateRules.maxLength(
                        propertyAddress.latitude,
                        'Latitude',
                        100
                      ),
                    ]"
                    dense
                    filled
                    label="Latitude"
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col class="d-none" md="4" sm="12">
                  <label>Longitude</label>
                  <v-text-field
                    v-model="propertyAddress.longitude"
                    :rules="[
                      validateRules.maxLength(
                        propertyAddress.longitude,
                        'Longitude',
                        100
                      ),
                    ]"
                    dense
                    filled
                    label="Longitude"
                    solo
                    flat
                    color="cyan"
                  ></v-text-field>
                </v-col>
                <v-col md="12" sm="12">
                  <v-row class="py-0">
                    <v-col class="py-0" md="6">
                      <label>Remarks</label>
                      <v-textarea
                        v-model="propertyAddress.remarks"
                        :rules="[
                          validateRules.maxLength(
                            propertyAddress.remarks,
                            'Remarks',
                            512
                          ),
                        ]"
                        auto-grow
                        dense
                        filled
                        label="Remarks"
                        solo
                        flat
                        row-height="30"
                        color="cyan"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-layout>
      <!-- </perfect-scrollbar> -->
    </v-col>
    <template v-if="!isDialog">
      <v-col
        class="custom-sidebar-container grey lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </template>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "property-address",
  title: "Customer",
  mixins: [CommonMixin, ValidationMixin, CustomerMixin],
  props: {
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    defaultPosition: {
      type: Object,
      default: function () {
        return {};
      },
    },
    propertyData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    property: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFocused: false,
      timeoutLimit: 500,
      timeout: null,
      trackGPS: false,
      autocompleteLoading: false,
      autocompleteList: new Array(),
      searchAddress: null,
      propertyAddress: {
        id: null,
        type: 1,
        is_same: 0,
        unit_no: null,
        street_1: null,
        street_2: null,
        country: "Singapore",
        zip_code: null,
        latitude: null,
        longitude: null,
        remarks: null,
      },
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    propertyAddress: {
      deep: true,
      immediate: true,
      handler(param) {
        param.latitude = param.latitude ? param.latitude.toString() : null;
        param.longitude = param.longitude ? param.longitude.toString() : null;
        this.$emit("savePropertyAddress", param);
      },
    },
    defaultPosition: {
      deep: true,
      handler(param) {
        if (param.lat && param.lng && !param.custom) {
          this.getStreetAddressFrom(param.lat, param.lng);
        }
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.propertyAddress.remarks = param.remarks;
        }
      },
    },
    propertyData: {
      deep: true,
      handler(param) {
        const _this = this;
        if (_this.lodash.isEmpty(param) === false) {
          _this.propertyAddress.id = param.id;
          _this.propertyAddress.type = param.type;
          _this.propertyAddress.is_same = param.is_same;
          _this.propertyAddress.unit_no = param.unit_no;
          _this.propertyAddress.street_1 = param.street_1;
          _this.propertyAddress.street_2 = param.street_2;
          _this.propertyAddress.zip_code = param.zip_code;
          _this.propertyAddress.country =
            _this.lodash.isEmpty(param.country) === false
              ? param.country.name
              : "Singapore";
          _this.propertyAddress.latitude = param.latitude;
          _this.propertyAddress.longitude = param.longitude;
          _this.propertyAddress.remarks = param.remarks;
        }
      },
    },
  },
  methods: {
    clearAddress() {
      const _this = this;
      _this.searchAddress = null;
      _this.propertyAddress.latitude = null;
      _this.propertyAddress.longitude = null;
      _this.propertyAddress.street_1 = null;
      _this.propertyAddress.street_2 = null;
      _this.propertyAddress.country = "Singapore";
      _this.propertyAddress.unit_no = null;
      _this.propertyAddress.zip_code = null;
    },
    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.searchAddress) {
          _this.trackGPS = false;
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.searchAddress },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.searchAddress = null;
              _this.propertyAddress.latitude = result.latitude;
              _this.propertyAddress.longitude = result.longitude;
              _this.propertyAddress.street_1 = result.street_1;
              _this.propertyAddress.street_2 = result.street_2;
              _this.propertyAddress.unit_no = result.unit_no;
              _this.propertyAddress.zip_code = result.zip_code;
              _this.$emit("updateMapMarker", {
                lat: result.latitude,
                long: result.longitude,
              });
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
    getStreetAddressFrom(lat, long) {
      const _this = this;
      try {
        if (lat && long) {
          _this.autocompleteLoading = true;
          _this.$store
            .dispatch(QUERY, {
              url: "google-api/lat-long",
              data: { lat, long },
            })
            .then((response) => {
              _this.$emit("updateMapMarker", { lat, long });
              _this.formatAddress(response.data);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.autocompleteLoading = false;
            });
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    getUserCurrentAddress() {
      const _this = this;
      if (_this.trackGPS === true) {
        _this.clearAddress();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            _this.getStreetAddressFrom(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          (error) => {
            _this.logError(error);
          }
        );
      }
    },
  },
  mounted() {
    if (this.property > 0) {
      this.propertyAddress = this.getPropertyById(this.customer, this.property);
      this.propertyAddress.country = this.propertyAddress.country_name;
    }
  },
};
</script>
