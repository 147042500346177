<template>
  <v-container fluid>
    <v-row class="gray-background">
      <v-col md="12">
        <v-skeleton-loader
          class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
          type="text"
          v-if="pageLoading"
        >
        </v-skeleton-loader>
        <p
          v-else
          class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
        >
          {{ detail.title }}
          <CustomStatus
            :status.sync="detail.status"
            :endpoint="'invoice/status'"
          ></CustomStatus>
        </p>
      </v-col>
      <v-col md="6">
        <template v-if="pageLoading">
          <v-skeleton-loader
            v-for="(d, i) in 4"
            :key="i"
            class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
            :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <table class="width-100">
            <tr>
              <td class="font-size-18 pt-3 pb-1" width="200">Invoice #</td>
              <td class="font-weight-600 font-size-18 pt-3 pb-1">
                <router-link
                  :to="
                    getDefaultRoute('invoice.detail', {
                      params: { id: detail.id },
                    })
                  "
                  >{{ detail.barcode }}</router-link
                >
              </td>
            </tr>
            <tr>
              <td class="font-size-18 py-1" width="200">Reference #</td>
              <td
                class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
              >
                {{ detail.reference }}
              </td>
            </tr>
            <tr v-if="lodash.isEmpty(detail.payment_due) === false">
              <td class="font-size-18 py-1" width="200">Payment due</td>
              <td
                class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
              >
                {{ detail.payment_due.text }}
              </td>
            </tr>
            <tr class="custom-border-top">
              <td
                class="font-size-18 py-1 custom-border-right pr-4"
                width="200"
              >
                Invoice date
              </td>
              <td
                class="font-size-18 px-4 py-1 custom-border-right"
                width="200"
              >
                Sent date
              </td>
              <td class="font-size-18 px-4 py-1" width="200">Due date</td>
            </tr>
            <tr>
              <td
                width="200"
                class="font-weight-600 font-size-18 py-1 pr-4 custom-border-right"
              >
                {{ formatDate(detail.invoice_date) }}
              </td>
              <td
                width="200"
                class="font-weight-600 font-size-18 py-1 px-4 custom-border-right"
              >
                {{ formatDate(detail.sent_date) }}
              </td>
              <td width="200" class="font-weight-600 font-size-18 py-1 px-4">
                {{ formatDate(detail.due_date) }}
              </td>
            </tr>
          </table>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { GET } from "@/core/services/store/request.module";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
export default {
  mixins: [CommonMixin],
  components: {
    CustomStatus,
  },
  props: {
    invoice: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      detail: {},
      pageLoading: false,
    };
  },
  watch: {
    invoice() {
      let invoice = this.lodash.toSafeInteger(this.invoice);
      if (invoice > 0) {
        this.getInvoice();
      }
    },
  },
  methods: {
    getInvoice() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "invoice/" + _this.invoice,
        })
        .then(({ data }) => {
          _this.detail = data.invoice;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    let invoice = this.lodash.toSafeInteger(this.invoice);
    if (invoice > 0) {
      this.getInvoice();
    }
  },
};
</script>
