<template>
  <v-container fluid>
    <v-row class="gray-background">
      <v-col md="12">
        <v-skeleton-loader
          class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
          type="text"
          v-if="pageLoading"
        >
        </v-skeleton-loader>
        <p
          v-else
          class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
        >
          {{ detail.title }}
          <Priority :priority.sync="detail.priority || 0"></Priority>
          <CustomStatus
            :status.sync="detail.status"
            endpoint="job/status"
          ></CustomStatus>
        </p>
        <v-skeleton-loader
          class="custom-skeleton my-auto custom-skeleton-full-width mr-5"
          type="sentences"
          v-if="pageLoading"
        >
        </v-skeleton-loader>
        <template v-else>
          <div>
            <read-more
              class="custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
              more-str="read more"
              :text="detail.description ? detail.description : ''"
              link="#"
              less-str="read less"
              :max-chars="200"
            >
            </read-more>
          </div>
        </template>
      </v-col>
      <v-col md="12">
        <v-skeleton-loader
          class="custom-skeleton height-30px mr-2 my-auto custom-skeleton-full-width"
          width="100"
          type="text"
          v-if="pageLoading"
        >
        </v-skeleton-loader>
        <v-chip
          v-else
          class="my-2 font-weight-600"
          color="chip-custom-blue"
          label
          outlined
        >
          {{ getScheduleType }}
        </v-chip>
        <template v-if="pageLoading">
          <v-skeleton-loader
            v-for="(d, i) in 4"
            :key="i"
            class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
            :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <table class="width-100">
            <tr>
              <td class="font-size-18 pt-3 pb-1" width="200">Job #</td>
              <td class="font-weight-600 font-size-18 pt-3 pb-1">
                <router-link
                  :to="
                    getDefaultRoute('job.detail', {
                      params: { id: detail.id },
                    })
                  "
                  >{{ detail.barcode }}</router-link
                >
              </td>
            </tr>
            <tr>
              <td class="font-size-18 py-1" width="200">Reference #</td>
              <td
                class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
              >
                {{ detail.reference }}
              </td>
            </tr>
            <tr>
              <td class="font-size-18 py-1" width="200">
                {{ StartedOn }}
              </td>
              <td
                class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
              >
                {{ formatDateTime(detail.started_at) }}
              </td>
            </tr>
            <tr>
              <td class="font-size-18 py-1" width="200">Lasts For</td>
              <td
                class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
              >
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <p class="m-0 max-content-width" v-bind="attrs" v-on="on">
                      {{ detail.lasts_for }}
                    </p>
                  </template>
                  <span>{{ formatDateTime(detail.finished_at) }}</span>
                </v-tooltip>
              </td>
            </tr>
            <tr v-if="detail.type === 2">
              <td class="font-size-18 py-1" width="200">Billing frequency</td>
              <td
                class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
              >
                {{ getInvoiceDuration }}
              </td>
            </tr>
            <tr v-if="detail.type === 2">
              <td class="font-size-18 py-1" width="200">Billing type</td>
              <td
                class="font-weight-600 font-size-18 py-1 custom-nowrap-ellipsis"
              >
                {{ getBillingType }}
              </td>
            </tr>
            <tr v-if="detail.type === 2">
              <td class="font-size-18 py-1" width="200">Schedule</td>
              <td class="font-weight-600 font-size-18 py-1">
                <v-skeleton-loader
                  class="custom-skeleton my-auto custom-skeleton-full-width"
                  type="sentences"
                  v-if="recurrenceLoading"
                >
                </v-skeleton-loader>
                <read-more
                  v-else
                  class="custom-read-more font-weight-600 font-size-16 pb-1 pr-2 custom-gray-color"
                  more-str="read more"
                  :text="getVisitScheduleType"
                  link="#"
                  less-str="read less"
                  :max-chars="60"
                >
                </read-more>
              </td>
            </tr>
          </table>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { GET } from "@/core/services/store/request.module";
import Priority from "@/view/pages/partials/Priority.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  components: {
    Priority,
    CustomStatus,
  },
  props: {
    job: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      detail: {},
      daily_recurrence: {},
      monthly_recurrence: {},
      weekly_recurrence: {},
      pageLoading: false,
      recurrenceLoading: false,
      invoiceScheduleList: [
        { id: 1, value: "Monthly on the last date of the month" },
        { id: 2, value: "After each visit is completed" },
        { id: 3, value: "As needed - no reminders" },
        { id: 4, value: "Once when job is closed" },
      ],
    };
  },
  watch: {
    job() {
      let job = this.lodash.toSafeInteger(this.job);
      if (job > 0) {
        this.getTicket();
      }
    },
  },
  methods: {
    loadRecurrence() {
      const _this = this;
      _this.recurrenceLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "job/" + _this.job + "/recurrence",
        })
        .then(({ data }) => {
          _this.daily_recurrence = data.daily_recurrence;
          _this.monthly_recurrence = data.monthly_recurrence;
          _this.weekly_recurrence = data.weekly_recurrence;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.recurrenceLoading = false;
        });
    },
    getTicket() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "job/" + _this.job,
        })
        .then(({ data }) => {
          _this.detail = data.ticket;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.loadRecurrence();
        });
    },
  },
  mounted() {
    let job = this.lodash.toSafeInteger(this.job);
    if (job > 0) {
      this.getTicket();
    }
  },
  computed: {
    StartedOn() {
      if (this.detail.started_at) {
        return moment().isAfter(this.detail.started_at)
          ? "Started On"
          : "Starts On";
      }
      return "Starts On";
    },
    getVisitScheduleType() {
      if (this.lodash.isEmpty(this.daily_recurrence) === false) {
        return this.daily_recurrence.message;
      }
      if (this.lodash.isEmpty(this.monthly_recurrence) === false) {
        return this.monthly_recurrence.message;
      }
      if (this.lodash.isEmpty(this.weekly_recurrence) === false) {
        return this.weekly_recurrence.message;
      }
      return "";
    },
    getInvoiceDuration() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.detail) === false) {
        let index = _this.lodash.findIndex(
          _this.invoiceScheduleList,
          function (row) {
            return row.id == _this.detail.invoice_duration;
          }
        );
        if (_this.lodash.isEmpty(_this.invoiceScheduleList[index]) === false) {
          return _this.invoiceScheduleList[index].value;
        }
      }
      return "";
    },
    getBillingType() {
      if (this.lodash.isEmpty(this.detail) === false) {
        if (this.detail.invoice_type === 1) {
          return "Per Visit";
        }
        if (this.detail.invoice_type === 2) {
          return "Fixed Price";
        }
      }
      return "";
    },
    getScheduleType() {
      if (this.detail.type === 1) {
        return "One-Off Job";
      }
      if (this.detail.type === 2) {
        return "Recurring Job";
      }
      return null;
    },
  },
};
</script>
