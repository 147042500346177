<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      origin="top center"
      v-model="deleteDialog"
      persistent
      content-class="delete-dialog"
      max-width="600"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          <slot name="title"></slot>
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <slot name="text"></slot>
          <slot name="validate"></slot>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="action"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
export default {
  props: {
    deleteDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>
